import { render, staticRenderFns } from "./editor-company.vue?vue&type=template&id=6035cbfd&scoped=true&"
import script from "./editor-company.vue?vue&type=script&lang=js&"
export * from "./editor-company.vue?vue&type=script&lang=js&"
import style0 from "./editor-company.vue?vue&type=style&index=0&id=6035cbfd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6035cbfd",
  null
  
)

export default component.exports