<template>
  <div class="edit">
    <h3>编辑企业</h3>
    <div class="model" style="margin-top: 50px"></div>
        <el-form ref="form" :model="formData" label-width="100px"  :rules="rules">
        <el-form-item label="公司名称" prop="company_name">
          <el-input v-model="formData.company_name" disabled></el-input>
        </el-form-item>

        <el-form-item label="公司简称" prop="abbreviation">
          <el-input v-model="formData.abbreviation"></el-input>
        </el-form-item>

        <el-form-item label="公司地址" prop="address">
          <el-input v-model="formData.address"></el-input>
        </el-form-item>
        <el-form-item label="公司简介" prop="description">
          <el-input type="textarea" v-model="formData.description"></el-input>
        </el-form-item>

        <el-form-item label="企业性质" prop="type">
          <el-select v-model="formData.type" placeholder="请选择">
            <!--0 民企 1 国企 2 私人企业-->
              <el-option label="民企" :value="0"></el-option>
              <el-option label="国企" :value="1"></el-option>
              <el-option label="私人企业" :value="2"></el-option>
            </el-select>
        </el-form-item>  
        <el-form-item label="公司LOGO	" prop="log_img" >
          <imageUpload v-model="formData.log_img"/>
        </el-form-item>
        <el-form-item label="营业执照	" prop="img" >
          <imageUpload v-model="formData.img"/>
        </el-form-item>

        <el-form-item label="企业介绍图片" prop="photo">
            <el-upload
              class="upload"
              :action="uploadFileUrl"
              :on-remove="(files) => handleRemove2(files)"
              :on-success="(response, files) => uploadFileSuccess2(response, files)"
              :on-error="uploadFileError"
              :file-list="formData.vivid_Files"
              list-type="picture-card"
              >
                <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        

        <el-form-item label="产品服务展示" prop="photo">
            <el-upload
              class="upload"
              :action="uploadFileUrl"
              :on-remove="(files) => handleRemove(files)"
              :on-success="(response, files) => uploadFileSuccess(response, files)"
              :on-error="uploadFileError"
              :file-list="formData.service_Files"
              list-type="picture-card"
              >
                <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        
        <el-form-item label="联系人姓名" prop="contact">
          <el-input v-model="formData.contact	"></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="formData.mobile	"></el-input>
        </el-form-item>

        <el-form-item label="验证码" prop="mobile_code">
          <el-input  v-model="formData.mobile_code"></el-input>

          <span @click="getsms" class="codestya" v-if="codeShow" style="cursor: pointer;">获取验证码</span>
          <span v-if="!codeShow" class="codestyb">{{ count }}秒后重试</span>
        </el-form-item>

        <el-form-item label="邮箱" prop="email" style="margin-top:40px" >
          <el-input  v-model="formData.email"></el-input>
        </el-form-item>
      
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
      
   
  
  </div>
</template>

<script>
  import { companyDetail,companyUpdate} from "@/api/manage/index";
  import {  mobilesms, } from "@/api/common/index";
  import FileUpload from '@/components/FileUpload';
import ImageUpload from '@/components/ImageUpload';
import urlConfig from '@/api/config'

export default {
  components: {
    FileUpload,ImageUpload
  },
  data() {
    return {
      uploadFileUrl:  urlConfig.prod.baseURL +"/web/file/Upload", // 上传的图片服务器地址

      formData:{
        company_name:"",
        abbreviation:"",//企业简、称
        address:"",
        description:"",
        type:"",
        contact:"",
        mobile:"",
        mobile_code:"",
        log_img:"",
        img:"",
        vivid_image:[],
        vivid_Files:[],
        service_Files:[],
        service_image:[],

        email:"",


      },

      rules: {
          
        company_name: [
              {required: true, message: '请填写公司名称', trigger: 'change'}
          ],
          abbreviation: [
              {required: true, message: '请填写公司简介', trigger: 'change'}
          ],
          address: [
              {required: true, message: '请填写公司地址', trigger: 'change'}
          ],
          description: [
              {required: true, message: '请填写公司简介', trigger: 'blur'}
          ],
          type: [
              {required: true, message: '请选择公司性质', trigger: 'blur'}
          ],
          log_img: [
              {required: true, message: '请上传公司LOGO', trigger: 'blur'}
          ],

          
          contact: [
              {required: true, message: '请填写联系人', trigger: 'blur'}
          ],

          mobile: [
              {required: true, message: '请填写联系电话', trigger: 'blur'}
          ],
          mobile_code: [
              {required: true, message: '请填写验证码', trigger: 'blur'}
          ],
          email: [
              {required: true, message: '请填写邮箱', trigger: 'blur'}
          ],
          
       },


      codeShow: true, //获取验证码倒计时
      timer: null, //定时器
      count: "", //倒计时数字
     
    };
  },

  mounted(){
    this.initInfo();
  },
  methods: {

    initInfo(){
      var that = this;
      companyDetail({}).then((response) => {
        console.log(response);
        if(response.code==200){
          that.formData=response.data

          var photoFiles=[];
          if(that.formData.vivid_image==null){
            that.formData.vivid_image=[]
          }
          for (let item of that.formData.vivid_image) {
            var file={
              name:"1111", url:item,
            }
              photoFiles.push(file);
          }
          that.formData.vivid_Files=photoFiles;


          if(that.formData.service_image==null){
            that.formData.service_image=[]
          }
          var photoFiles2=[];
          for (let item of that.formData.service_image) {
            var file={
              name:"1111", url:item,
            }
            photoFiles2.push(file);
          }
          that.formData.service_Files=photoFiles2;



        }else{
          
          that.$message.error(response.msg?response.msg:"请求错误");
        }
      });
    },

    onSubmit(){
      var params={
        company_name:this.formData.company_name,
        abbreviation:this.formData.abbreviation,
        address:this.formData.address,
        description:this.formData.description,
        type:this.formData.type,
        contact:this.formData.contact,
        mobile:this.formData.mobile,
        mobile_code:this.formData.mobile_code,
        email:this.formData.email,

        vivid_image:this.formData.vivid_image,
        service_image:this.formData.service_image,
        log_img:this.formData.log_img,
        img:this.formData.img,
    }

      this.$refs["form"].validate((valid) => {
          if (valid) {
            companyUpdate(params).then((response) => {
                    console.log(response);
                    if(response.code==200){
                      this.$message({
                        message: "提交成功",
                        type: 'success'
                      });
                    }else{
                      this.$message({
                        message: response.msg?response.msg:"提交失败",
                      });
                    }
                });
            } 
        });
    },



    // 上传失败
    uploadFileError(err) {
      this.$message.error("上传失败, 请重试");
    },

    // 删除文件
    handleRemove(files) {
      console.log(files)
      var index =  this.formData.service_Files.indexOf(files)
      this.formData.service_Files.splice(index, 1);
      this.formData.service_image.splice(index, 1);
     
    },
  

     // 上传成功的钩子函数
     uploadFileSuccess(response, files) {
      console.log(response);
      if(response.code==1){
        var file={
          name:response.filename, url:response.file,
        }
        this.formData.service_Files.push(file);
        this.formData.service_image.push(response.file);
      }
    },



        // 删除文件
   handleRemove2(files) {
      console.log(files)
      var index =  this.formData.vivid_Files.indexOf(files)
      this.formData.vivid_Files.splice(index, 1);
      this.formData.vivid_image.splice(index, 1);
     
    },
  

     // 上传成功的钩子函数
     uploadFileSuccess2(response, files) {
      console.log(response);
      if(response.code==1){
        var file={
          name:response.filename, url:response.file,
        }
        this.formData.vivid_Files.push(file);
        this.formData.vivid_image.push(response.file);
      }
    },
      


    getsms () {
      if (this.formData.mobile == "") {
        this.$message({
          type: "warning",
          message: "手机号不能为空",
        });
        return;
      }
      const reg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/;
      if (reg.test(this.formData.mobile)) {
        let data = {
          mobile: this.formData.mobile,
          mobile_type:10
        };
        mobilesms(data).then((res) => {
          if (res.code == 200) {
            this.flag = false;
            this.$message({
              message: res.msg,
              type: "success",
            });
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.codeShow = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.codeShow = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.flag = true;
          }
        });
      } else {
        this.$message({
          type: "warning",
          message: "手机号格式错误",
        });
      }
    },
    
  },
};
</script>

<style lang="scss" scoped>

/deep/ .el-input{
  width: 300px !important;
}
.edit {
  padding: 20px;
  background-color: white;
}


.codestya {
  color: #4c71db;
  position: absolute;
  left: 250px;
  top: 40px;
  font-size: 12px;
}
.codestyb {
  color: #4c71db;
  position: absolute;
  left: 250px;
  top: 40px;
  font-size: 12px;
}
</style>
